<template>
  <section class="checkPeopleJury-index">
    <div class="search-component">
      <div class="title">数据筛选</div>
      <div class="header-container">
        <div class="item">
          <div class="text">快速搜索：</div>
          <el-input v-model="table.query.keyword" placeholder="请输入关键词" size="small"></el-input>
        </div>
        <el-button type="primary" class="keyword" size="small" @click="search">查询</el-button>
        <el-button size="small" @click="reset">重置</el-button>
      </div>
    </div>
    <div class="main">
      <div class="main-header">
        <div class="router2-name">人民陪审员列表</div>
        <div class="btn">
          <!-- <el-button type="primary" size="small" @click="changeComponent('randomSelect','分类统计')">分类统计</el-button> -->
          <el-button size="small" @click="exportExcel">导出EXCEL</el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          v-loading="table.loading"
          :data="table.data"
          default-expand-all
          row-key="id"
          height="100%"
          :header-cell-style="{'text-align':'center','background-color':'#FAFAFA'}"
          :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="index" label="序号" :index="indexMath"></el-table-column>
          <el-table-column prop="nd" label="年度"></el-table-column>
          <el-table-column prop="sfzhm" label="身份证号码" width="180"></el-table-column>
          <el-table-column prop="xm" label="姓名">
            <template slot-scope="scope">
              <span class="blue" @click="showInfo(scope.row)">{{scope.row.xm}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="xb" label="性别" width="50"></el-table-column>
          <el-table-column prop="bmfs_a" label="报名方式"></el-table-column>
          <el-table-column prop="jhlx_text" label="计划类型"></el-table-column>
          <el-table-column prop="csrq" label="出生日期">
            <template slot-scope="scope">
              <span v-if="scope.row.csrq">{{scope.row.csrq}}</span>
              <span v-else>{{scope.row.sfzhm|csrq}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="lxfs" label="联系方式"></el-table-column>
          <el-table-column prop="cjzd" label="常住地址" width="300"></el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="table.query.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="table.query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="table.total"
      ></el-pagination>
    </div>
    <personInfo v-show="showPerson" ref="personInfo" @closeInfo="closeInfo"></personInfo>
  </section>
</template>

<script>
import PeopleJury from '@/api/choose/peopleJury';
import numberToString from '@/plugin/numberToString';
export default {
  name: 'peopleJury',
  props: {},
  data() {
    return {
      showPerson: false,
      table: {
        data: [],
        query: {
          page: 1,
          size: 100,
          keyword: '',
          from: '',
          bmfs: '',
        },
        loading: false,
        total: 0,
        options: {
          bmfs: {
            children: [],
          },
          jhlx: {
            children: [],
          },
        },
      },
      componentName: '',
      show: false,
    };
  },
  methods: {
    showInfo(data) {
      this.showPerson = true;
      this.$nextTick(() => {
        this.$refs.personInfo.toggle(data);
      });
    },
    closeInfo() {
      this.showPerson = false;
    },
    exportExcel() {
      this.$store.commit('changeLoading', {
        loading: true,
        title: '正在导出excel,由于数据可能较多，请稍后',
        color: 'rgba(255,255,255,0.8)',
      });
      let { total } = this.table;
      let { keyword, bmfs, from } = this.table.query;
      PeopleJury.peopleList_sfs({
        keyword,
        bmfs,
        from,
        page: 1,
        size: total,
      }).then((res) => {
        // 将审查状态由数字 变为可读的中文
        let data = numberToString(res.items, 'bmfs', this.table.options.bmfs.children);
        let cou = [
          { label: '序号', key: 'index', length: 100 },
          { label: '年度', key: 'nd', length: 100 },
          { label: '身份证号码', key: 'sfzhm', length: 180 },
          { label: '姓名', key: 'xm', length: 100 },
          { label: '性别', key: 'xb', length: 100 },
          { label: '报名方式', key: 'bmfs_a', length: 100 },
          { label: '出生日期', key: 'csrq', length: 100 },
          { label: '联系方式', key: 'lxfs', length: 120 },
          { label: '常住地址', key: 'cjzd', length: 400 },
        ]
        // this.$exportExcel({
        //   name: `人民陪审员列表(${res.total}条)`,
        //   data: data,
        //   header: [
        //     { label: '序号', key: 'index', length: 100 },
        //     { label: '年度', key: 'nd', length: 100 },
        //     { label: '身份证号码', key: 'sfzhm', length: 180 },
        //     { label: '姓名', key: 'xm', length: 100 },
        //     { label: '性别', key: 'xb', length: 100 },
        //     { label: '报名方式', key: 'bmfs_a', length: 100 },
        //     { label: '出生日期', key: 'csrq', length: 100 },
        //     { label: '联系方式', key: 'lxfs', length: 120 },
        //     { label: '常住地址', key: 'cjzd', length: 400 },
        //   ],
        // });
        this.$exportExcel(cou,data,`人民陪审员列表(${res.total}条)`)
      });
    },
    indexMath(index) {
      let { page, size } = this.table.query;
      return index + 1 + (page - 1) * size;
    },
    // 修改component隐藏
    changeShow(show) {
      this.show = show;
      if (!show) {
        this.componentName = '';
      }
    },
    // 报名方式改变
    changeType(type) {
      if (type === this.type) {
        return;
      }
      this.table.query.bmfs = type;
      return this.getList();
    },
    // 每页条数变化
    handleSizeChange(data) {
      this.table.query.size = data;
      this.page = 1;
      return this.getList();
    },
    // 当前页数变化
    handleCurrentChange(data) {
      if (data === this.table.query.page) {
        return;
      } else {
        this.table.query.page = data;
        return this.getList();
      }
    },
    reset() {
      let { from } = this.table.query;
      this.table.query = {
        page: 1,
        size: 100,
        keyword: '',
        from,
        bmfs: '',
      };
      return this.getList();
    },
    search() {
      return this.getList();
    },
    getList() {
      this.table.loading = true;
      PeopleJury.peopleList_sfs(this.table.query)
        .then((res) => {
          // 将审查状态由数字 变为可读的中文
          let data = numberToString(res.items, 'bmfs', this.table.options.bmfs.children);
          console.log(data);
          this.table.data = data;
          this.table.total = res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    changeComponent(name, data) {
      console.log(name, data);
    },
    windowKeyDown(e) {
      if (e.key === 'Enter') {
        console.log('点击');
        return this.search();
      }
    },
  },
  mounted() {},
  created() {
    let { bmfs } = this.$store.getters.dic;
    this.table.options = { bmfs };
    window.addEventListener('keydown', this.windowKeyDown);
    return this.getList();
  },
  computed: {},
  watch: {
    '$store.getters.active_top_nav': {
      handler: function (n) {
        switch (n.meta.title) {
          case '选任': {
            this.table.query.from = 'xrjh';
            break;
          }
          case '增补': {
            this.table.query.from = 'zbjh';
            break;
          }
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.windowKeyDown);
  },
};
</script>

<style scoped lang="scss">
.checkPeopleJury-index {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  .header {
    margin-bottom: 20px;
  }
  .search-component {
    background-color: #fff;
    margin-bottom: 20px;
    .title {
      color: $h4;
      border-bottom: 1px solid $border1;
      padding: 15px 30px;
      font-weight: bold;
    }
    .header-container {
      padding: 15px 30px;
      display: flex;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
      }
      .keyword {
        margin-left: 20px;
      }
      .text {
        min-width: 70px;
        font-size: 14px;
        color: #666666;
      }
      .el-input {
        width: 224px;
        margin-right: 10px;
      }
    }
  }
  .main {
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    .main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      .router2-name {
        color: $h4;
        padding: 15px 0;
        font-weight: bold;
      }
      .el-button {
        margin-right: 20px;
      }
    }

    .table {
      flex: 1;
      padding-bottom: 50px;
      .el-table {
        width: calc(100% - 20px);
        border: 1px solid rgba(232, 232, 232, 1);
        border-bottom: none;
        .header-bgc {
          background-color: $border4;
        }
        .operate {
          width: 100%;
          display: flex;
        }
      }
    }
    .el-pagination {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
.blue {
  color: #0079fe;
  font-weight: bold;
}
</style>
